var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "quickSearchAgencyContacts",
        size: "lg",
        scrollable: "",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "people" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Cerca Anagrafiche Contatti")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSearch(_vm.filterName)
            },
          },
        },
        [
          _c("b-row", [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    name: "last_name",
                    type: "text",
                    label: "Cognome",
                    placeholder: "Inserisci un cognome",
                  },
                  model: {
                    value: _vm.filter.byAttribute.SURN,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "SURN", $$v)
                    },
                    expression: "filter.byAttribute.SURN",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    name: "name",
                    type: "text",
                    label: "Nome",
                    placeholder: "Inserisci un nome",
                  },
                  model: {
                    value: _vm.filter.byAttribute.NAME,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "NAME", $$v)
                    },
                    expression: "filter.byAttribute.NAME",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    name: "NINO",
                    type: "text",
                    label: "Codice Fiscale",
                    placeholder: "Inserisci un codice fiscale",
                  },
                  model: {
                    value: _vm.filter.byAttribute.NINO,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "NINO", $$v)
                    },
                    expression: "filter.byAttribute.NINO",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    name: "CNAM",
                    type: "text",
                    label: "Ragione Sociale",
                    placeholder: "Inserisci una ragione sociale",
                  },
                  model: {
                    value: _vm.filter.byAttribute.CNAM,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "CNAM", $$v)
                    },
                    expression: "filter.byAttribute.CNAM",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-input", {
                  attrs: {
                    name: "CREG",
                    type: "text",
                    label: "PIVA",
                    placeholder: "Inserisci una partita IVA",
                  },
                  model: {
                    value: _vm.filter.byAttribute.CREG,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "CREG", $$v)
                    },
                    expression: "filter.byAttribute.CREG",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-select", {
                  attrs: {
                    name: "CLI",
                    label: "Cliente",
                    options: _vm.opt_radio,
                  },
                  model: {
                    value: _vm.filter.byAttribute.CLI,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "CLI", $$v)
                    },
                    expression: "filter.byAttribute.CLI",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("base-select", {
                  attrs: {
                    name: "SUP",
                    label: "Fornitore",
                    options: _vm.opt_radio,
                  },
                  model: {
                    value: _vm.filter.byAttribute.SUP,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter.byAttribute, "SUP", $$v)
                    },
                    expression: "filter.byAttribute.SUP",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("b-row", [
            _c(
              "div",
              { staticClass: "form-group col-md-6 align-self-end" },
              [
                _c(
                  "b-button",
                  { attrs: { type: "submit", variant: "lisaweb", size: "sm" } },
                  [_vm._v("Cerca")]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-reset",
                    attrs: { type: "button", variant: "lisaweb", size: "sm" },
                    on: {
                      click: function ($event) {
                        return _vm.onClearFilter(_vm.filterName)
                      },
                    },
                  },
                  [_vm._v("Reset")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("agency-contacts-accounting", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          filterOn: {
            byRelations: ["byCorrespondence", "byAddressBook"],
          },
          noInnerWidth: "",
          noActions: "",
          includeRowSelector: "",
          noInputPaginator: "",
        },
        on: { rowSelector: _vm.onModalSelector },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }