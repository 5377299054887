<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="book_date"
                        :name="beForm[rep].book_date.label"
                        :label="beForm[rep].book_date.label"
                        v-model="form[rep].book_date"
                        :rules="getRules('book_date')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_date"
                        :name="beForm[rep].effective_date.label"
                        label="Data Valuta"
                        v-model="form[rep].effective_date"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="invoice_protocol_number"
                        :name="beForm[rep].invoice_protocol_number.label"
                        :label="beForm[rep].invoice_protocol_number.label"
                        v-model="form[rep].invoice_protocol_number"
                        placeholder="Inserisci un numero di protocollo"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div
                      class="form-group col-md-4"
                      v-if="form[rep].agencybook_type !== 2"
                    >
                      <base-select
                        :name="beForm[rep].payment_reference_id.label"
                        vid="payment_reference_id"
                        :label="beForm[rep].payment_reference_id.label"
                        v-model="form[rep].payment_reference_id"
                        :options="opt_payment_reference"
                        :rules="getRules('payment_reference_id')"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form[rep].agencybook_type == 2"
                    >
                      <base-select
                        :name="beForm[rep].sector_id.label"
                        vid="balance"
                        label="Saldo"
                        v-model="form.balance"
                        :options="opt_sectors"
                        :rules="getRules('sector_id')"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="
                        (form[rep].agencybook_type == 0 ||
                          form[rep].agencybook_type == 3) &&
                        !disable_receipt
                      "
                    >
                      <base-select
                        :name="beForm[rep].sector_id.label"
                        vid="receipt"
                        label="Entrate"
                        v-model="form.receipt"
                        :options="opt_sectors"
                        :rules="getRules('sector_id')"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="
                        (form[rep].agencybook_type == 1 ||
                          form[rep].agencybook_type == 3) &&
                        !disable_expenses
                      "
                    >
                      <base-select
                        :name="beForm[rep].sector_id.label"
                        vid="expenses"
                        label="Uscite"
                        v-model="form.expenses"
                        :options="opt_sectors"
                        :rules="getRules('sector_id')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div
                      class="form-group col-md-4"
                      v-if="
                        form[rep].agencybook_type == 0 ||
                        form[rep].agencybook_type == 1
                      "
                    >
                      <base-select
                        name="salesmen"
                        vid="salesmen"
                        label="Produttori"
                        v-model="form.salesman_id"
                        :options="opt_salesmen"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="
                        form[rep].agencybook_type == 0 ||
                        form[rep].agencybook_type == 1
                      "
                    >
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica Contatti"
                              vid="agency_contact_id"
                              label="Anagrafica Contatti"
                              v-model="form.inpt_label"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchAgencyContacts"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="
                        form[rep].agencybook_type == 0 ||
                        form[rep].agencybook_type == 1
                      "
                    >
                      <span
                        v-if="agency_contacts_data"
                        class="info"
                        v-html="
                          toInfoData(agency_contacts_data, 'agency_contacts')
                        "
                      >
                      </span>
                    </div>
                    <quick-search-agency-contacts-modal
                      ref="quickSearchAgencyContacts"
                      @input="handleAgencyContactsModalInput"
                    ></quick-search-agency-contacts-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="additional_description"
                        :name="beForm[rep].additional_description.label"
                        :label="beForm[rep].additional_description.label"
                        v-model="form[rep].additional_description"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('additional_description')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].gross.label"
                        vid="gross"
                        :label="beForm[rep].gross.label"
                        v-model="form[rep].gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('gross')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchAgencyContactsModal from "@/components/modals/quickSearchAgencyContact";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import { toInfoData } from "@/utils/transforms";

export default {
  mixins: [FormMixin, ShortcutMixin, ShowMixin, StorageGetterMixin],
  data() {
    return {
      repository: "agency_book",
      id: this.$route.params.id,
      item: this.$route.params.item,
      agency_contacts_data: null,
      disable_receipt: false,
      disable_expenses: false,
      opt_salesmen: [],
      opt_sectors: [],
      opt_payment_reference: [],
      form: {
        salesman_id: null,
        agency_contact_id: null,
        quick_value: "",
        inpt_label: "",
        agency_book: {
          book_date: null,
          effective_date: null,
          agencybook_type: null,
          gross: null,
          invoice_protocol_number: null,
          opening_balance: "N",
          payment_reference_id: null,
          sector_id: null,
          broker: {},
          agency_contact: {},
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseInput,
    BaseSelect,
    BaseCurrency,
    QuickSearchAgencyContactsModal,
  },
  methods: {
    toInfoData,
    onSubmit() {
      this.setFields();
      this.isLoading = true;
      this.setReferers();
      this.update(this.repository, this.id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Movimento Modificato`,
          });
          this.shortcut(
            "module.CONT.CONA",
            null,
            "#AgencyBooks",
            "filterAgencyBook"
          );
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setReferers() {
      if (this.form.salesman_id) {
        this.form[this.rep].broker = { [this.form.salesman_id]: {} };
      }
      if (this.form.agency_contact_id) {
        this.form[this.rep].agency_contact = {
          [this.form.agency_contact_id]: {},
        };
      }
    },
    handleAgencyContactsModalInput(value) {
      this.form.quick_value = value.id;
      this.form[this.rep].agency_contact_id = value.id;
      this.form.inpt_label = value.attributables.CNAM
        ? value.attributables.CNAM
        : `${value.attributables.NAME}-${value.attributables.SURN}`;
      this.agency_contacts_data = value;
      this.$bvModal.hide("quickSearchAgencyContacts");
    },
    openQuickSearchAgencyContacts() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchAgencyContacts");
    },
    setBroker() {
      if (this.item.brokers.length) {
        this.form.salesman_id = this.item.brokers[0].id;
      }
    },
    setAgencyContact() {
      if (this.item.agency_contacts.length) {
        this.agency_contact_id = this.item.agency_contacts[0].id;
        this.agency_contacts_data = this.item.agency_contacts[0];
        if (this.agency_contacts_data) {
          this.form.inpt_label = this.agency_contacts_data.attributables.CNAM
            ? this.agency_contacts_data.attributables.CNAM
            : `${this.agency_contacts_data.attributables.NAME}-${this.agency_contacts_data.attributables.SURN}`;
        }
      }
    },
    setTreasury() {
      switch (this.form[this.rep].agencybook_type) {
        case 0:
          this.form.receipt = this.form[this.rep].sector_id;
          break;
        case 1:
          this.form.expenses = this.form[this.rep].sector_id;
          break;
        case 2:
          this.form.balance = this.form[this.rep].sector_id;
          this.form[this.rep].opening_balance = "Y";
          break;
        case 3:
          if (this.form[this.rep].gross > 0) {
            this.form.receipt = this.form[this.rep].sector_id;
            this.disable_expenses = true;
          } else {
            this.form.expenses = this.form[this.rep].sector_id;
            this.disable_receipt = true;
          }
          break;
      }
    },
    setFields() {
      switch (this.form[this.rep].agencybook_type) {
        case 0:
          this.form[this.rep].sector_id = this.form.receipt;
          break;
        case 1:
          this.form[this.rep].sector_id = this.form.expenses;
          this.form[this.rep].gross = -this.form[this.rep].gross;
          break;
        case 2:
          this.form[this.rep].sector_id = this.form.balance;
          break;
        case 3:
          this.form[this.rep].sector_id = this.form.receipt
            ? this.form.receipt
            : this.form.expenses;
      }
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getSectors: "sectors",
      getPaymentReference: "payment_references",
    }),
  },
  mounted() {
    // this.opt_payment_reference = this.getPaymentReference();
    this.opt_salesmen = this.getSalesmen();
    this.opt_sectors = this.getSectors().filter(
      (sector) => sector.is_agency === "Y"
    );
  },
  created() {
    this.isLoading = true;
    /* */
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("payment_references"))
      resources.push(
        this.$store
          .dispatch(`${path}payment_references`)
          .then(() => {
            console.info(
              `background data "${path}payment_references" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          // this.getReports();
          this.opt_payment_reference = this.getPaymentReference();
          this.fetchEditForm(this.repository, this.id).then(() => {
            this.setBroker();
            this.setAgencyContact();
            this.setTreasury();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      // this.getReports();
      this.opt_payment_reference = this.getPaymentReference();
      this.fetchEditForm(this.repository, this.id).then(() => {
        this.setBroker();
        this.setAgencyContact();
        this.setTreasury();
        this.isLoading = false;
      });
      this.isLoading = false;
    }
    /* */
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
